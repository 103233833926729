// src/Layout.js
import React, { useState, useEffect } from "react";
import logo from "./logo.svg";
import Team from "./Team";
import JsonData from "./data/data.json";
import SocialLinks from "./socialLinks";

const Layout = ({ children }) => {
  const [landingPageData, setLandingPageData] = useState({});
  useEffect(() => {
    setLandingPageData(JsonData);
  }, []);

  return (
    <div className="App">
      <header className="App-header">
        <img src={logo} className="App-logo" alt="logo" />
        <h1 className="App-slogan">AI Community @ Silicon Valley</h1>
      </header>
      <main>{children}</main>
      <Team data={landingPageData.Team} />
      <SocialLinks />
    </div>
  );
};

export default Layout;
