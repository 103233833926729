import React from "react";

const SocialLinks = () => {
  return (
    <div className="social-links">
      <a
        href="https://twitter.com/alignsAI"
        target="_blank"
        rel="noopener noreferrer"
      >
        <img
          src="/img/social/twitter_logo_black.png"
          alt="Twitter Logo"
          className="social-logo"
        />
        <span className="social-text">Follow us on Twitter</span>
      </a>
      <a
        href="https://join.slack.com/t/alignsai/shared_invite/zt-20yeq7t3q-gjB~HdcY52clIl6855cEqQ"
        target="_blank"
        rel="noopener noreferrer"
      >
        <img
          src="/img/social/slack_black.png"
          alt="Slack Logo"
          className="social-logo"
        />
        <span className="social-text">Join us on Slack</span>
      </a>
    </div>
  );
};

export default SocialLinks;
