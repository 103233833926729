import React from "react";

export const Team = (props) => {
  return (
    <div id="team" className="text-left">
      <div className="container">
        <div className="col-md-8 col-md-offset-2 section-title">
          <p>
            &#x1F916; We are a vibrant non-profit AI community born at Stanford
            University, comprising students, engineers, AI researchers and
            domain experts from Stanford, Berkeley, Google, Meta, Nvidia and a
            variety of other leading institutions in Silicon Valley and beyond.
          </p>

          <p>
            &#x1F4A1; Since March 2023, we have hosted a series of events, both
            online and offline in Silicon Valley, aligning different individuals
            and ideas and fostering more consensus through open and thoughtful
            exchanges of knowledge and insights in this challenging new field.
            Our ultimate goal is to ensure that AI benefits humanity in a
            benevolent and safe manner.
          </p>

          <p>
            &#x1F310; If you're interested in speaking at aligns.ai events,
            sponsoring us, or collaborating with us in any other way, please
            reach out to{" "}
            <a href="mailto:contact@aligns.ai">contact@aligns.ai</a>.
          </p>
        </div>
      </div>
    </div>
  );
};

export default Team;
